<template>
	<div v-if="props.page?.slug && props.page?.slug === '404'">
		<main-content
			:title="props.page?.title || props.page?.header"
			:subtitle="props.page?.subtitle"
			:content="props.page?.content"
		/>
	</div>
	<div v-else>
		<slot />
	</div>
</template>

<script setup>
const props = defineProps({
	page: { type: Object, default: () => {} },
});
</script>
